import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="header-container">
    <nav className="header-nav">
      <span className="title">
        {siteTitle}
      </span>
      <span className="nav-link">
        <Link to="#projects">
          Projects
        </Link>
      </span>
      <span className="nav-link">
        <Link to="#graphic-design">
          Graphic Design
        </Link>
      </span>
      <span className="nav-link">
        <Link to="#about">
          About
        </Link>
      </span>
      <span className="nav-link">
        <Link to="#contact">
          Contact
        </Link>
      </span>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
